<!-- <div class="container">
  <div class="footer_toolbar">
    <img src="../../../assets/images/note_icon.png" class="note_iconIMG" alt="">
    <div class="resBtn">
      {{user.FooterNoteText}} 
      <div [innerHTML]="user.FooterNoteText"></div>
    </div> 
   </div> -->
<!-- </div> -->

<footer class="footer">
  <div class="container bottom_border">
    <div class="row">
      <div class="col-lg-2 col-md-2 col-sm-4 col-12 col">
        <h5 class="font-weight-normal text-uppercase text-white pt2">Company</h5>
        <ul class="footer_ul">
          <li><a href="#">About</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">Team</a></li>
          <li><a href="#">Career</a></li>
        </ul>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-4 col-12 col">
        <h5 class="font-weight-normal text-uppercase text-white pt2">For you</h5>
        <ul class="footer_ul">
          <li><a href="#">Help & Support</a></li>
          <li><a href="#">Add Restaurant</a></li>
          <li><a href="#">Partner with us</a></li>
          <li><a href="#">Products of business</a></li>
          <li><a href="#">Business app</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-4 col-12 col">
        <h5 class="font-weight-normal text-uppercase text-white pt2">Legal</h5>
        <ul class="footer_ul">
          <li><a target="_blank" href="https://zufoloapp.s3.amazonaws.com/Terms/ZufoloTerms.html">Terms & condition</a></li>
          <li><a href="#">Refund & collections</a></li>
          <li><a target="_blank" href="https://zufoloapp.s3.amazonaws.com/Terms/ZufoloTerms.html">Privacy policy</a></li>
          <li><a href="#">Cookie policy</a></li>
        </ul>
      </div>

      <div class="col-lg-2 col-md-2 col-sm-4 col-12 col">
        <h5 class="font-weight-normal text-uppercase text-white pt2">Social Media</h5>
        <ul class="footer_ul social-media">
          <li><a href="#"><img alt="social-logo" src="../../../assets/images/social/facebook.png" /></a></li>
          <li><a href="#"><img alt="social-logo" src="../../../assets/images/social/instagram.jpg" /></a></li>
          <li><a href="#"><img alt="social-logo" src="../../../assets/images/social/LinkedIn-Logo.png" /></a></li>
          <li><a href="#"><img alt="social-logo" src="../../../assets/images/social/youtube.png" /></a></li>
        </ul>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-12 col text-center text-white">
        <h5 class="text-uppercase font-weight-normal text-white pt2">
          <img src="../../../assets/images/logo.png" width="50px" /> Zufolo
        </h5>
        <ul class="footer_ul_last">
          <li>
            <img src="../../../assets/images/app-store.png" width="170px" />
          </li>
          <li>
            <img src="../../../assets/images/play-store.png" width="170px" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
<div class="DesclaimersPopup" *ngIf="!DesclaimersDiv">
  <span>
    <div class="cli-bar-container cli-style-v2">
      <div class="cli-bar-message">You can change your cookie settings <br>*Please note that making changes may affect
        the
        functionality and experience on the app or website.<br>You can view our Cookie Policy for a description of the
        following cookie types by clicking
        <a target="_black" href="https://zufoloapp.s3.amazonaws.com/Terms/ZufoloTerms.html"> here</a>
      </div>
      <div class="cli-bar-btn_container">
        <a role="button" tabindex="0" (click)="openTerms()" class="cli_settings_button"
          style="margin: 0px 10px 0px 5px; color: rgb(255, 255, 255);">Cookie settings</a>
        <a role="button" tabindex="0" (click)="acceptTerms()" id="cookie_action_close_header"
          class="medium cli-plugin-button cli-plugin-main-button cookie_action_close_header cli_action_button"
          style="display: inline-block; color: rgb(255, 255, 255); background-color: rgb(97, 162, 41);">ACCEPT</a>
      </div>
    </div>
  </span>
</div>

<!-- <footer class="footer">
  <hr>
  <div class="container">
    <span class="text-muted">
      &copy; {{currentYear}}
      <a href="https://mathisgarberg.no">Mathis Garberg</a>,
      <a href="mailto:tom.h.anderson@gmail.com">Tom H Anderson</a>,
        and contributors.
      <a href="https://github.com/mathisGarberg/angular-folder-structure">
        https://github.com/mathisGarberg/angular-folder-structure
      </a>
    </span>
  </div>
</footer> -->