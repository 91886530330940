import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { ContentLayoutComponent } from "./layout/content-layout/content-layout.component";
import { NoAuthGuard } from "@app/guard/no-auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full"
  },
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [NoAuthGuard], // Should be replaced with actual auth guard
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("@modules/home/home.module").then(m => m.HomeModule)
      },
      {
        path: "about",
        loadChildren: () =>
          import("@modules/about/about.module").then(m => m.AboutModule)
      },
      {
        path: "contact",
        loadChildren: () =>
          import("@modules/contact/contact.module").then(m => m.ContactModule)
      },
      {
        path: "search",
        loadChildren: () =>
          import("@modules/search/search.module").then(m => m.SearchModule)
      },
      {
        path: "review",
        loadChildren: () =>
          import("@modules/review/review.module").then(m => m.ReviewModule)
      },
      {
        path: "help",
        loadChildren: () =>
          import("@modules/help/help.module").then(m => m.HelpModule)
      },
      {
        path: "analyze",
        loadChildren: () =>
          import("@modules/analyze/analyze.module").then(m => m.AnalyzeModule)
      },
      {
        path: "vouchers",
        loadChildren: () =>
          import("@modules/vouchers/vouchers.module").then(
            m => m.VouchersModule
          )
      },
      {
        path: "restaurant",
        loadChildren: () =>
          import("@modules/restaurant/restaurant.module").then(
            m => m.RestaurantModule
          )
      },
      {
        path: "offer",
        loadChildren: () =>
          import("@modules/offer/offer.module").then(m => m.OfferModule)
      },
      {
        path: "z-bucks",
        loadChildren: () =>
          import("@modules/z-bucks/z-bucks.module").then(m => m.ZBucksModule)
      },
      {
        path: "secret-shopper",
        loadChildren: () =>
          import("@modules/secret-shopper/secret-shopper.module").then(
            m => m.SecretShopperModule
          )
      },
      {
        path: "event",
        loadChildren: () =>
          import("@modules/event/event.module").then(m => m.EventModule)
      },
      {
        path: "venue",
        loadChildren: () =>
          import("@modules/venue/venue.module").then(m => m.VenueModule)
      },
      {
        path: "check-in",
        loadChildren: () =>
          import("@modules/check-in/check-in.module").then(m => m.CheckInModule)
      },
      {
        path: "analitics",
        loadChildren: () =>
          import("@modules/analitics/analitics.module").then(
            m => m.AnaliticsModule
          )
      },
      {
        path: "topbottom-categories",
        loadChildren: () =>
          import(
            "@modules/topbottom-categories/topbottom-categories.module"
          ).then(m => m.TopbottomCategoriesModule)
      },
      {
        path: "topbottom-dishes",
        loadChildren: () =>
          import("@modules/topbottom-dishes/topbottom-dishes.module").then(
            m => m.TopbottomDishesModule
          )
      },
      {
        path: "topbottom-keywords",
        loadChildren: () =>
          import("@modules/topbottom-keywords/topbottom-keywords.module").then(
            m => m.TopbottomKeywordsModule
          )
      }
    ]
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("@modules/auth/auth.module").then(m => m.AuthModule)
  },
  // Fallback when no prior routes is matched
  { path: "**", redirectTo: "/auth/login", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
