import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { tap, delay, finalize, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";
import { MustMatch } from "../../../../must-match.validator";
import { AuthService } from "@app/service/auth.service";

@Component({
  selector: "app-forgot-password-reset",
  templateUrl: "./forgot-password-reset.component.html",
  styleUrls: ["./forgot-password-reset.component.scss"]
})
export class ForgotPasswordResetComponent implements OnInit {
  error: string;
  isLoading: boolean;
  resetpasswordForm: FormGroup;
  response: any = "";
  submitted: boolean;
  email_id: any;
  otp: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService
  ) {
    this.email_id = sessionStorage.getItem("email");
    this.otp = sessionStorage.getItem("otp");
    this.buildForm();
  }

  ngOnInit(): void {}

  get f() {
    return this.resetpasswordForm.controls;
  }

  reset_password() {
    this.isLoading = true;
    this.submitted = true;

    if (this.resetpasswordForm.invalid) {
      if (this.resetpasswordForm.value.password == "") {
        this.toastr.errorToastr("Enter Password", "Oops!");
      } else if (this.resetpasswordForm.value.confirm_password == "") {
        this.toastr.errorToastr("Enter Confirm Password", "Oops!");
      }

      this.isLoading = false;
      this.submitted = false;
      return;
    }

    let param = {
      email: this.email_id,
      password: this.resetpasswordForm.value.password,
      confirmPassword: this.resetpasswordForm.value.confirm_password,
      otp: this.otp
    };

    this.response = this.authService.post("user/forgotPasswordReset", param);
    this.response.subscribe(data => {
      this.isLoading = false;
      this.submitted = false;

      console.log(data);

      if (data["status"] == "success") {
        this.toastr.successToastr(data["message"], "Success!");
      } else {
        this.toastr.errorToastr(data["data"]["message"], "Oops!");
      }
    }),
      err => {
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("HttpErrorResponse", "Oops!");
      };
  }

  private buildForm(): void {
    this.resetpasswordForm = this.formBuilder.group(
      {
        password: ["", Validators.required],
        confirm_password: ["", Validators.required]
      },
      {
        validator: MustMatch("password", "confirm_password")
      }
    );
  }
}
