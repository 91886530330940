import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: 'app-zufolo',
  templateUrl: './zufolo.component.html',
  styleUrls: ['./zufolo.component.scss']
})
export class ZufoloComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  go_page(page_name)
  {
    this.router.navigate([page_name]);
  }
}
