import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { AnalyticsService } from "@app/service/analytics.service";
import { AuthService } from "@app/service/auth.service";
import { ToastrManager } from "ng6-toastr-notifications";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
  userName = "";
  profilePic = "assets/imgs/logo.png";
  localAvatar = "";
  contentHeight: any;
  canShow: boolean = false;
  loggedInUser: any;
  firstName: any;
  lastName: any;
  displayName: any;
  pwd: any = "";
  npwd: any = "";
  ncpwd: any = "";
  constructor(
    private authService: AuthService,
    private toastr: ToastrManager,
    private changeRef: ChangeDetectorRef,
    private api: AuthService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile() {
    this.authService.get("user/getProfile?username=" + sessionStorage.getItem("emailId")).subscribe((res: any) => {
      // this.util.hide();
      this.userName = sessionStorage.getItem("emailId");
      console.log(res);
      const profile: any = res.Data;
      this.firstName = profile.firstName;
      this.lastName = profile.LastName;
      this.displayName = profile.DisplayName;
      this.profilePic = profile.PhotoPath;
      localStorage.setItem("userPic", this.profilePic);
      localStorage.setItem("name", this.displayName);
      console.log("firstname =>", this.firstName);
      this.changeRef.detectChanges();
    }, error => {
      console.log(error);
    });
  }

  getUserPic() {
    return localStorage.getItem("userPic") ? localStorage.getItem("userPic") : "assets/imgs/logo.png";
  }

  getText(e) {
    const elementValue = e.srcElement.value;
    if (elementValue) {
      const regex = /^[A-z]+$/;
      const tempValue = elementValue.substring(0, elementValue.length - 1);
      if (!regex.test(elementValue)) {
        // this.alertService.alertBasic("Alert!!!","","Only alphabets are allowed","Ok");
        e.srcElement.value = tempValue;
      }
    }
  }

  updateProfile() {
    const body = {
      email: sessionStorage.getItem("emailId"),
      FirstName: this.firstName && this.firstName != null && this.firstName !== "null" ? this.firstName : "",
      LastName: this.lastName && this.lastName != null && this.lastName !== "null" ? this.lastName : "",
      DisplayName: this.displayName && this.displayName != null && this.displayName !== "null" ? this.displayName : "",
      PhotoPath: this.profilePic || ""
    }
    this.api.post("user/updateProfile", body).subscribe((data: any) => {
      console.log("update", data);
      if (data && data.status === "success") {
        localStorage.setItem("displayName", this.displayName ? this.displayName : this.loggedInUser.userName);
        localStorage.setItem("userPic", this.profilePic);
        this.analyticsService.setUserProperty('profilecompleted','true');
        this.toastr.successToastr("Profile updated successfully", "Success!");
      }
    }, error => {
      console.log(error);
      this.toastr.errorToastr("Something went wrong", "Error!");
    });
  }

  save() {
    // this.dismiss();
    if (this.pwd === "" || this.npwd === "" || this.ncpwd === "") {
      this.toastr.errorToastr("Error \n All Fields are required", "Error!");
      return false;
    }
    if (this.ncpwd !== this.npwd) {
      this.toastr.errorToastr("Error \n Password does not match", "Error!");
      return false;
    }
    const param = {
      email: sessionStorage.getItem("emailId"),
      currentPassword: this.pwd,
      newPassword: this.npwd,
      confirmNewPassword: this.ncpwd
    };
    this.api.post("user/changePassword", param).subscribe((data: any) => {
      console.log("send", data);

      if (data && data.status === "success") {
        this.toastr.successToastr("Password Changed succesfully", "Success!");
        this.pwd = "";
        this.npwd = "";
        this.ncpwd = "";
        // Swal.fire({
        //   title: "Success",
        //   text: "Password Changed succesfully",
        //   icon: "success",
        //   timer: 1000,
        //   backdrop: false,
        //   background: "white"
        // });
        // this.dismiss();
      }
    }, error => {
      console.log(error);
      // this.util.hide();
      if (error && error.error && error.error.message) {
        this.toastr.errorToastr("Error \n " + error.error.message, "Error!");
      } else {
        this.toastr.errorToastr("Error \n Something went wrong", "Error!");
      }
    });
  }
}
