import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

import { NgModule } from "@angular/core";

import { CoreModule } from "@app/core.module";
import { SharedModule } from "@shared/shared.module";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { DatePipe } from "@angular/common";
import { ContentLayoutComponent } from "./layout/content-layout/content-layout.component";
import { NavComponent } from "./layout/nav/nav.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AuthModule } from "@modules/auth/auth.module";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ng6-toastr-notifications";
import { NgxSelectModule } from "ngx-select-ex";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatDialogModule } from "@angular/material/dialog";
import { RatingComponent } from "./modules/search/page/rating/rating.component";
import { AgmCoreModule } from "@agm/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { AgmDirectionModule } from "agm-direction";

import { DragulaModule, DragulaService } from 'ng2-dragula';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialLoginModule,
  SocialAuthServiceConfig
} from 'angularx-social-login';
@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    NavComponent,
    FooterComponent,
    AuthLayoutComponent
  ],
  imports: [
    // angular
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxSelectModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    FormsModule,
    ToastModule,
    ReactiveFormsModule,
    // 3rd party
    AuthModule,
    DialogModule,
    ButtonModule,
    InfiniteScrollModule,
    // core & shared
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(),
    // app
    AppRoutingModule,


    DragulaModule.forRoot(),
    SocialLoginModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDBMaqk8i8SrCa_-OuROiziC8rmSptfzkQ",
      // apiKey: "AIzaSyDtTBTxs0CGVQmjERiV_hbIFBK6DL1RrlY",
      libraries: ["places"]
    }),
    AgmDirectionModule,
  ],
  entryComponents: [RatingComponent],
  providers: [
    DatePipe,
    MessageService,
    DragulaService,
    {
      provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '865261271244-ijvr0eslbbep23d0m68fjqpghhu3pu6l.apps.googleusercontent.com'
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider('712705605807724')
            }
          ]
        } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
