<div class="bottom-shadow">
    <div class="row rest-header justify-content-center p-2">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
            <div class="profile-img">
                <img alt="profile-img" class="" *ngIf="!profilePic"
                    src="../../../../../assets/images/profile/profile.jpg" />
                <img alt="profile-img" class="" *ngIf="profilePic" [src]="profilePic"
                    onerror='this.src="../../../../../assets/images/profile/profile.jpg";' />

            </div>
            <div class="title ml-3">
                <!-- <h4 class="profile-name">Rahul Rojasara</h4> -->
                <h4 class="profile-name">{{displayName}}</h4>
                <!-- <p class="email">yourmail@gmail.com</p> -->
                <p class="email">{{userName}}</p>
                <button class="btn btn-outline-dark radius-0 py-1 px-4">Edit Profile</button>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center text-sm-right buttons">
            <button class="btn btn-orange text-white radius-0 mb-2 py-1 px-4">Active Pro Feature</button>
            <button class="btn btn-purple text-white radius-0 mb-2 py-1 px-4" data-toggle="modal"
                data-target="#addRestaurantModal" (click)="save()">Update Password</button>
        </div>
    </div>
</div>
<div class="container">
    <div class="bottom-shadow">
        <div class="row rest-header justify-content-center p-2">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center">
                <div class="profile-img">
                    <img alt="profile-img" class="" *ngIf="!profilePic"
                        src="../../../../../assets/images/profile/profile.jpg" />
                    <img alt="profile-img" class="" *ngIf="profilePic" [src]="profilePic"
                        onerror='this.src="../../../../../assets/images/profile/profile.jpg";' />

                </div>
                <div class="title ml-3">
                    <!-- <h4 class="profile-name">Rahul Rojasara</h4> -->
                    <h4 class="profile-name">{{displayName}}</h4>
                    <!-- <p class="email">yourmail@gmail.com</p> -->
                    <p class="email">{{userName}}</p>
                    <button class="btn btn-outline-dark radius-0 py-1 px-4">Edit Profile</button>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 text-center text-sm-right buttons">
                <button class="btn btn-orange text-white radius-0 mb-2 py-1 px-4">Active Pro Feature</button>
                <button class="btn btn-purple text-white radius-0 mb-2 py-1 px-4" data-toggle="modal"
                    data-target="#addRestaurantModal" (click)="save()">Update Password</button>
            </div>
        </div>
    </div>
    <div class="card" style="max-width: 100%;">
        <div class="card-body">
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="firstName" placeholder="Enter Firstname">
                </label>
            </div>
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="lastName" placeholder="Enter Lastname">
                </label>
            </div>
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="displayName" placeholder="Enter Displayname">
                </label>
            </div>
            <button class="btn btn-purple" (click)="updateProfile()">Update Info</button>
        </div>
    </div>
    <div class="card" style="max-width: 100%;">
        <div class="card-body">
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="pwd" placeholder="Enter Current Password">
                </label>
            </div>
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="npwd" placeholder="Enter New Password">
                </label>
            </div>
            <div class="form-group form-field">
                <label class="d-block">
                    <input type="text" class="form-control" [(ngModel)]="ncpwd" placeholder="Confirm New Password">
                </label>
            </div>
            <button class="btn btn-purple" (click)="save()">Update Password</button>
        </div>
    </div>

</div>