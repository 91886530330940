import { Component, OnInit, Optional, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserService } from "@data/service/user.service";

@Component({
  selector: "app-rating",
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss"]
})
export class RatingComponent implements OnInit {
  rating: any;
  homeButton: boolean;
  constructor(
    public dialogRef: MatDialogRef<RatingComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public user: UserService
  ) { }

  ngOnInit(): void {
    this.user.FooterNoteText = '';
    this.rating = this.data.detailedScore;
    this.homeButton = this.data.homeButton ? this.data.homeButton : false;
    console.log(this.rating, this.homeButton);
  }

  close() {
    this.dialogRef.close("Thanks for using me!");
  }

  goToHome() {
    this.dialogRef.close("Thanks for using me!");
    this.router.navigate(['/search']);
  }
}
