<!--<div class="alert alert-info" role="alert">
  Username: Mathis \\
  Password: 12345
</div>-->
<h3 class="title text-center text-uppercase font-weight-bold text-dark m-4">Login</h3>
<div [hidden]="!error" class="alert alert-danger">
  {{ error }}
</div>

<fieldset [disabled]="isLoading">
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div class="form-group form-field">
      <label class="d-block">
        <input type="text" class="form-control" [placeholder]="'Email ID'" formControlName="email">
        <app-control-messages [control]="f['email']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <label class="d-block">
        <input [type]="isShowPassword ? 'text' : 'password'" class="form-control" [placeholder]="'Password'"
          formControlName="password">
        <a *ngIf="isShowPassword" (click)="showPassword()"><i class="pi pi-eye show-password i-1"></i></a>
        <a *ngIf="!isShowPassword" (click)="showPassword()"><i class="pi pi-eye-slash show-password i-1"></i></a>
        <app-control-messages [control]="f['password']"></app-control-messages>
      </label>
    </div>
    <div class="row m-0 moformm">
      <div class="form-group form-field pl-1 col-sm-6">
        <label class="custom-check">
          <!-- <input type="checkbox" formControlName="RememberMe"  (Change)="lsRememberMe()" id="rememberMe"> -->
          <input type="checkbox" formControlName="RememberMe" (change)="lsRememberMe()" id="rememberMe">
          <span class="checkmark"></span>
          &nbsp; Remember me
        </label>
      </div>
      <div class="form-group form-field col-sm-6 " style="text-align: center;">
        <a [routerLink]="['/auth/forgot-password']" class="forgot-password">Forgot Password?</a>
      </div>
    </div>
    <div class="form-group form-field">
      <button type="submit" class="btn btn-primary btn-block btn-raised" [disabled]="submitted">
        <span [hidden]="isLoading">Submit</span>
        <span [hidden]="!isLoading">
          <fa-icon icon="asterisk" size="1x" [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
    <p class="my-2 font-weight-bold text-center ">Or Login with</p>
    <div class="social my-2">
      <a href="#" class="mx-2" (click)="loginWithFacebook()"><img alt="social-icon" src="/assets/images/social/facebook.png" />
        Facebook</a>
      <a href="#" class="mx-2" (click)="loginWithGoogle()"><img alt="social-icon" src="/assets/images/social/google-plus.png" /> Google</a>
    </div>
    <!-- <div class="text-center continue-guest m-4" (click)="guestBtn()">
      <a href="#" class=""><i class="pi pi-user"></i> Continue as Guest</a>
    </div> -->
    <div class="text-center">
      <a [routerLink]="['/auth/register']" class="font-weight-bold">Don't have an account? <u>Click here</u> to sign up</a>
      <!-- <a [routerLink]="['/auth/register']" class="font-weight-bold">Create New Account</a> -->
    </div>
    <!-- <div class="text-center" style="margin-top: 5px;">
      <a [routerLink]="['/auth/login']" class="font-weight-bold">I Have Already Account</a>
    </div> -->
  </form>
</fieldset>