<h3 class="title text-center text-uppercase font-weight-bold text-dark m-4">Reset Password</h3>
<fieldset [disabled]="isLoading">
  <form (ngSubmit)="reset_password()" [formGroup]="resetpasswordForm">
    <div class="form-group form-field">
      <label class="d-block">
        <input type="password" class="form-control" [placeholder]="'Password'" formControlName="password">
        <app-control-messages [control]="f['password']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <label class="d-block">
        <input type="password" class="form-control" [placeholder]="'Confirm Password'"
          formControlName="confirm_password">
        <app-control-messages [control]="f['confirm_password']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <button type="submit" class="btn btn-primary btn-block btn-raised" type="submit"
        [disabled]="submitted">
        <span [hidden]="isLoading">Submit</span>
        <span [hidden]="!isLoading">
          <fa-icon icon="asterisk" size="3x" [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
  </form>
</fieldset>