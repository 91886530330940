<h5 class="title text-center text-uppercase font-weight-bold text-dark m-4">New Account</h5>

<form (ngSubmit)="registration()" [formGroup]="registerForm">
  <div class="form-group form-field">
    <label class="d-block">
      <input type="text" class="form-control" [placeholder]="'Email ID'" formControlName="email">
      <app-control-messages [control]="f['email']"></app-control-messages>
    </label>
  </div>
  <div class="form-group form-field">
    <label class="d-block">
      <input type="text" class="form-control" [placeholder]="'Phone No.'" formControlName="PhoneNumber">
      <app-control-messages [control]="f['PhoneNumber']"></app-control-messages>
    </label>
  </div>

  <div class="form-group form-field">
    <label class="d-block">
      <input [type]="isShowPassword ? 'text' : 'password'" class="form-control" [placeholder]="'Password'"
        formControlName="password">
      <a *ngIf="isShowPassword" (click)="showPassword()"><i class="pi pi-eye show-password i-1"></i></a>
      <a *ngIf="!isShowPassword" (click)="showPassword()"><i class="pi pi-eye-slash show-password i-1"></i></a>
      <app-control-messages [control]="f['password']"></app-control-messages>
    </label>
  </div>
  <div class="form-group form-field">
    <label class="d-block">
      <input [type]="isShowConfirmPassword ? 'text' : 'password'" class="form-control"
        [placeholder]="'Confirm Password'" formControlName="confirmPassword">
      <a *ngIf="isShowConfirmPassword" (click)="showConfirmPassword()"><i class="pi pi-eye show-password i-1"></i></a>
      <a *ngIf="!isShowConfirmPassword" (click)="showConfirmPassword()"><i
          class="pi pi-eye-slash show-password i-1"></i></a>
      <app-control-messages [control]="f['confirmPassword']"></app-control-messages>
    </label>
  </div>
  <div class="form-group form-field">
    <label class="d-block">
      <!--<select class="form-control" placeholder="Select Country" formControlName="cc">
        <option *ngFor="let c of countries" Value="{{c.dialling_code}}">{{c.country_name}}</option>
      </select>-->
      <ngx-select formControlName="cc" [allowClear]="true" [items]="countries" optionValueField="dialling_code"
        optionTextField="country_name" placeholder="Select Country">
      </ngx-select>
      <app-control-messages [control]="f['cc']"></app-control-messages>
    </label>
  </div>
  <!--<div class="form-group form-field">
    <button class="btn btn-primary btn-block btn-raised" type="submit">
      <span>Sign Up</span>
    </button>
  </div>-->
  <div class="form-group form-field text-center">
    <label class="custom-check">I agree to the <a class="underline" href="https://zufoloapp.s3.amazonaws.com/Terms/ZufoloTerms.html" target="_blank">terms and conditions</a>
      <input type="checkbox" formControlName="privacyAccept" [value]="privacyAccept">
      <span class="checkmark"></span>
    </label>
    <label class="ml-2" for="aggree"> </label>
  </div>
  <div class="form-group form-field">
    <button type="submit" class="btn btn-primary btn-block btn-raised text-uppercase" type="submit"
      [disabled]="submitted">
      <span [hidden]="isLoading">Create Account</span>
      <span [hidden]="!isLoading">
        <fa-icon icon="asterisk" size="1x" [spin]="true"></fa-icon>
      </span>
    </button>
  </div>
  <!-- <div class="form-group text-center continue-guest">
    <a class="mt-2 mb-2"><i class="pi pi-user"></i> Continue as Guest</a>
  </div> -->
</form>