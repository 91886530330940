<app-nav></app-nav>
<div class="auth-box mb-4">
  <div class="container">
    <div class="card col-lg-5 col-md-7 col-sm-12 mt-3 mx-auto border-0">
      <div class="card-body">
        <!-- <h4 class="card-title text-center">
              <fa-icon icon="user-circle" size="3x"></fa-icon>
            </h4> -->
        <!-- <h1 class="card-title text-center mb-4 text-uppercase">
          <img src="../../../assets/images/logo.png" width="20%" /> Zufolo
        </h1> -->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>