<fieldset>
    <div class="form-group">
        <button type="button" class="btn btn-outline-primary btn-block btn-raised" (click)="go_page('search')">
            <img alt="icon" class="btn-icon" src="../../../../../assets/images/home-icon/1d.png" />
            <span class="button-text">Search</span>
        </button>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-outline-primary btn-block btn-raised" (click)="go_page('review')">
            <img alt="icon" class="btn-icon" src="../../../../../assets/images/home-icon/2d.png" />
            <span class="button-text">Review</span>
        </button>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-outline-primary btn-block btn-raised" (click)="go_page('/restaurant/venue-detail')">
            <img alt="icon" class="btn-icon" src="../../../../../assets/images/home-icon/3d.png" />
            <span class="button-text">Owner Portal</span>
        </button>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-outline-primary btn-block btn-raised" (click)="go_page('vouchers')">
            <img alt="icon" class="btn-icon" src="../../../../../assets/images/home-icon/4d.png" />
            <span class="button-text">My Vouchers</span>
        </button>
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-outline-primary btn-block btn-raised" (click)="go_page('help')">
            <img alt="icon" class="btn-icon" src="../../../../../assets/images/home-icon/5d.png" />
            <span class="button-text">Get Some Help</span>
        </button>
    </div>
</fieldset>