<h3 class="title text-center text-uppercase font-weight-bold text-dark m-4">Forgot Password</h3>
<fieldset [disabled]="isLoading">
  <form (ngSubmit)="forgotpassword()" [formGroup]="fpasswordForm">
    <div class="form-group form-field">
      <label class="d-block">
        <input type="text" class="form-control" [placeholder]="'Email ID'" formControlName="email">
        <app-control-messages [control]="f['email']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <div class="col-md-12 well rowCell">

        <label>
          <input type="radio" value="email" formControlName="method">&nbsp;
          <span>Email</span>
        </label>&nbsp;&nbsp;
        <label>
          <input type="radio" value="mobile" formControlName="method">&nbsp;
          <span>Mobile</span>
        </label>
      </div>
    </div>
    <div class="form-group form-field">
      <button type="submit" class="btn btn-primary btn-block btn-raised" type="submit"
        [disabled]="submitted">
        <span [hidden]="isLoading">Submit</span>
        <span [hidden]="!isLoading">
          <fa-icon icon="asterisk" size="1x" [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
    <a [routerLink]="['/auth/login']">Got an account already? Log in here!</a>
  </form>
</fieldset>