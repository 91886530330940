import { Injectable } from '@angular/core';
import firebase from 'firebase';
// import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '@env';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private router: Router
  ) { 
    firebase.initializeApp(environment.firebaseConfig);
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      console.log('route changed: ', e);
      console.log('route changed: ', e.url.split('?')[0]);
      let url = e.url.split('?')[0];
      switch (e.url) {
        case "/search":
          url = "Search page";
          break;
        case "/review/review":
          url = "review page";
          break;
        case "/restaurant/venue-detail":
          url = "Owner Portal";
          break;
        case "/vouchers":
          url = "Voucher";
          break;
        case "/help":
          url = "Help";
          break;
          case  "/search/search-details":
            url = "RestaurantLanding";
            break;
        default:
      }
      this.trackScreen(url)
    })
  }

  setUserId(email){
    // this.analyticsService.setUserProperty("name", displayName);
    console.log("LOG userId", email)
    firebase.analytics().setUserId(email)
  }

  trackScreen(name: string) {
    console.log("LOG trackScreen", name)
    firebase.analytics().setCurrentScreen(name);
  }

  logEvent(name: string, properties: object) {
  
    console.log("LOG EVENT", name, properties)
    firebase.analytics().logEvent(name, properties); // Ex: "select_content", {content_type: "page_view", item_id: "home"}
  }



  setUserProperty(key: string, value: string) {
    let param = {
      key,
      value
    }
    firebase.analytics().setUserProperties(param);
  }
}
