import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";

@Component({
  selector: "app-alertforaction",
  templateUrl: "./alertforaction.component.html",
  styleUrls: ["./alertforaction.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertforactionComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AlertforactionComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogDetail
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.dialogDetail);
  }
}
