<h3 class="title text-center text-uppercase font-weight-bold text-dark m-4">OTP Verification
</h3>
<fieldset [disabled]="isLoading">
  <form (ngSubmit)="otp_verify()" [formGroup]="otpForm">
    <div class="form-group form-field">
      <label class="d-block">
        <input type="text" class="form-control" [placeholder]="'Email ID'" [(ngModel)]="email_id"
          formControlName="email" readonly>
        <app-control-messages [control]="f['email']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <label class="d-block">
        <input type="text" class="form-control" maxlength="4" [placeholder]="'OTP'" formControlName="otp">
        <app-control-messages [control]="f['otp']"></app-control-messages>
      </label>
    </div>
    <div class="form-group form-field">
      <button type="submit" class="btn btn-primary btn-block btn-raised" type="submit" [disabled]="submitted">
        <span [hidden]="isLoading">Submit</span>
        <span [hidden]="!isLoading">
          <fa-icon icon="asterisk" size="1x" [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
  </form>
</fieldset>