import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { ToastrManager } from "ng6-toastr-notifications";
import { AuthService } from "@app/service/auth.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "app-register-otp",
  templateUrl: "./register-otp.component.html",
  styleUrls: ["./register-otp.component.scss"]
})
export class RegisterOtpComponent implements OnInit {
  // error: string;
  // isLoading: boolean;
  // submitted: boolean;
  // otpForm: FormGroup;
  response: any = "";
  email_id: any;
  otp: any;
  userData: any;
  isLoading = false;
  submitted = false;
  hasOtpSent = false;
  constructor(
    // private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService,
    private _location: Location,
    private activatedRoute: ActivatedRoute
  ) {
    // this.email_id = sessionStorage.getItem("emailId");
    // this.buildForm();
  }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(param=>{
      console.log(param);
      if(param){
        this.userData = param;
      }
    })
    this.sendOtp();
  }

  sendOtp(){
    let param = {
      email: this.userData.email
    }
    this.authService.post('user/registerSendOTP', param).subscribe(data=>{
      console.log("otp has been sent successfully =>", data)
      
      // this.toastr.successToastr('OTP has been sent successfully.', 'success', 'bottom');
      this.hasOtpSent = true;
      this.toastr.successToastr("we've sent a code to your registered email, please enter the code below.", 'success', 'bottom');
    },err=>{
      console.log("Error sending otp =>", err)
      this.toastr.errorToastr('error sending OTP !!', 'danger', 'bottom');
    })
  }

  verifyOtp(){
    if(this.otp && this.otp.length === 4){
      let param = {
        email: this.userData.email,
        otp: this.otp
      }
      console.log(this.response);
      // this.response = this.authService.post("user/forgotPasswordOTP", param);
      this.response = this.authService.post('user/registerVerifyOTP', param);
        this.response.subscribe(data => {
          console.log("otp valid =>", data);
          const navData: NavigationExtras = {
            queryParams: {
              from: 'signup'
            }
          };
          // toastr.errorToastr
          if(data.status === 'error'){
            this.toastr.errorToastr('Invalid OTP');
          }else{
            // this.toastr.successToastr('Thanks for your registration');
            this.registerUser();
            console.log("otp valid => Thanks for your registration");
          }
          // this.router.navigate(['food-taste'], navData);
          // this.router.navigate(['/search/user-profile']);
          // this.router.navigate(['/restaurant/review-restaurant'], navData);
        },err => {
            console.log("otp not valid =>", err);
            this.toastr.errorToastr('OTP is not valid !!', 'danger', 'bottom');
            // return err;
          })
        // }),this.response
        //   .pipe(
        //   catchError(err => {
        //     console.log("otp not valid =>", err);
        //     this.toastr.errorToastr('OTP is not valid !!', 'danger', 'bottom');
        //     return throwError(err);
        //   })
        // )
        
      //   .catch(err => {
      //   console.log("otp not valid =>", err);
      //   this.toastr.errorToastr('OTP is not valid !!', 'danger', 'bottom');
      // })
    }else{
      this.toastr.errorToastr('OTP is not valid.', 'danger', 'bottom');
      return;
    }
  }

  registerUser(){
    let param = {
      email: this.userData.email,
      password: this.userData.password,
      confirmPassword: this.userData.confirmPassword,
      cc: this.userData.cc,
      PhoneNumber: this.userData.PhoneNumber
    }
      this.response = this.authService.post("user/register", param)
      .pipe(
        catchError(err => {
          console.log("Handling error locally and rethrowing it...", err);
          this.isLoading = false;
          this.submitted = false;
          if (err && err.error && err.error.message) {
            console.log('___________dddddd____', err.error.message)
            this.toastr.errorToastr(err.error.message);
            this.router.navigate(['/auth/register']);
          }
         return throwError(err);

        })
      )
    this.response.subscribe(data => {
      this.isLoading = false;
      this.submitted = false;
      console.log(data);

      if (data["status"] == "success") {
        // this.router.navigate(["/auth/register-otp"]);
        // sessionStorage.setItem('UserId', this.registerForm.value.email);
        // sessionStorage.setItem('AccessToken', data.data.AccessToken);

        sessionStorage.setItem("UserId", data["data"]["UserId"]);
        sessionStorage.setItem("AccessToken", data.data.AccessToken);
        sessionStorage.setItem("emailId", this.userData.email);
          //this.router.navigate(["/dashboard/home"]);
        // this.router.navigate(["/search"]);
        this.router.navigate(['/search/user-profile']);
        

        this.toastr.successToastr(data["message"], "Success!");
      } else {
        console.log("data error message email ");
        this.toastr.errorToastr(data["data"]["message"], "Oops!");
      }
    }),
      error => {
        console.log("data error message email ", error);
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("HttpErrorResponse", "Oops!");
      }
  //   this.response.catch((error) => {
  //     console.error('error__', error);
  //  })
  }


  backGO() {
    this._location.back();
  }
}
