import { Component } from "@angular/core";
import { AuthService } from "@app/service/auth.service";
import { UserService } from "@data/service/user.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
  DesclaimersDiv: boolean;
  currentYear = new Date().getUTCFullYear();
  constructor(
    public user: UserService,
    private authService: AuthService,
  ) {
    // this.user.FooterNoteText = 'Note: Focus on low scoring categories & keywords to improve your business ranking.';
  }
  async ngOnInit() {
    let acceptedDesclaimers = localStorage.getItem('acceptedDesclaimers');
    console.log('acceptedDesclaimers', acceptedDesclaimers)
    if (acceptedDesclaimers && acceptedDesclaimers != null) {
      // $('#DesclaimersModal').modal('hide');
      this.DesclaimersDiv = true;
    } else {
      // $('#DesclaimersModal').modal('show');
      this.DesclaimersDiv = false;
      // sessionStorage.setItem('acceptedDesclaimers')
    }
  }

  async registerLogForPrivacy() {
    const body = {
      email: sessionStorage.getItem("emailId"),
      description: `User has accepted Zufolo photos desclaimers, Photos that appear on the Zufolo app/website are
      not provided by Zufolo but by indexed searches to third party sites,
      the source of these photos are not affiliated with Zufolo or endorsed by the originating websites.`,
      termsId: 2
    };
    this.authService.post('user/termsAcceptance', body).subscribe((res: any) => {
      // this.util.hide();
      console.log('user/termsAcceptance', res);
    }, error => {
      // this.util.hide();
      console.log('eror', error);
    });
  }


  acceptTerms() {
    console.log("____________DAAAAAAAAAAAAAAAAAA")
    this.registerLogForPrivacy();
      localStorage.setItem('acceptedDesclaimers', 'true');
      // $('#DesclaimersModal').modal('hide');
      this.DesclaimersDiv = true;
      // $('#galleryModal_primary').modal('show');
  }

}


