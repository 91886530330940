<h3 class="title text-center text-uppercase font-weight-bold text-dark m-4">OTP Verification
</h3>
 <p *ngIf="hasOtpSent">we've sent a code to your registered email, please enter the code below. </p>
<!-- <fieldset> -->
  <!-- <p>{{data.errorMessage}}</p> -->
  <form>
    <div class="form-group form-field">
      <label class="d-block">
        <!-- <input type="text"  class="form-control" [(ngModel)]="otp" maxlength="4" placeholder="OTP" > -->
        <input type="text" class="form-control" [(ngModel)]="otp" name="otp" maxlength="4" placeholder="OTP">
        <!-- <app-control-messages [control]="f['otp']"></app-control-messages> -->
      </label>
    </div>
    <div class="form-group form-field">
      <button type="submit" class="btn btn-primary btn-block btn-raised" type="submit" (click)="verifyOtp()">
        <span>Submit</span>
        <!-- <span [hidden]="!isLoading">
          <fa-icon icon="asterisk" size="1x" [spin]="true"></fa-icon>
        </span> -->
      </button>
    </div>
  </form>
<!-- </fieldset> -->