import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"]
})
export class SpinnerComponent {
  @Input() public isLoading = true;
  @Input() public message: string;
}
