import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { tap, delay, finalize, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";

import { AuthService } from "@app/service/auth.service";

@Component({
  selector: "app-forgot-password-otp",
  templateUrl: "./forgot-password-otp.component.html",
  styleUrls: ["./forgot-password-otp.component.scss"]
})
export class ForgotPasswordOtpComponent implements OnInit {
  error: string;
  isLoading: boolean;
  submitted: boolean;
  otpForm: FormGroup;
  response: any = "";
  email_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService
  ) {
    this.email_id = sessionStorage.getItem("email");
    this.buildForm();
  }

  ngOnInit(): void {}

  get f() {
    return this.otpForm.controls;
  }

  otp_verify() {
    //console.log(this.otpForm.value);
    this.isLoading = true;
    this.submitted = true;

    if (this.otpForm.invalid) {
      if (this.otpForm.value.email == "") {
        this.toastr.errorToastr("Enter Email ID", "Oops!");
      } else if (!this.isvalidEmail(this.otpForm.value.email)) {
        this.toastr.errorToastr("Enter Valid Email ID", "Oops!");
      } else if (this.otpForm.value.otp == "") {
        this.toastr.errorToastr("Enter OTP", "Oops!");
      }

      this.isLoading = false;
      this.submitted = false;
      return;
    }

    let param = {
      email: this.otpForm.value.email,
      otp: this.otpForm.value.otp
    };

    this.response = this.authService.post("user/forgotPasswordOTP", param);
    this.response.subscribe(data => {
      this.isLoading = false;
      this.submitted = false;

      console.log(data);

      if (data["status"] == "success") {
        sessionStorage.setItem("otp", this.otpForm.value.otp);
        this.router.navigate(["/auth/forgot-password-reset"]);
        this.toastr.successToastr(data["message"], "Success!");
      } else {
        this.toastr.errorToastr(data["data"]["message"], "Oops!");
      }
    }),
      err => {
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("HttpErrorResponse", "Oops!");
      };
  }

  private buildForm(): void {
    this.otpForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      otp: ["", Validators.required]
    });
  }
  isvalidEmail(email) {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
}
