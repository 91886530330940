<div class="container p-0">
    <div class="inner-content" *ngIf="rating && rating.length > 0">
        <p class="p-2 mb-2 bg-purple text-white" *ngFor="let res of rating">{{res.category}}
            <span style="float: right;" *ngIf="!(res.cityRank)">{{res.score}}/{{res.total}}</span>
            <span style="float: right;" *ngIf="res.cityRank &&
             res.cityRank.totalRestaurants && res.cityRank.cityName">
                #{{res.cityRank.restaurantRank}}/{{res.cityRank.totalRestaurants}} in
                {{res.cityRank.cityName}}
            </span>
        </p>
    </div>
    <div class="inner-content" *ngIf="!rating || rating.length === 0">
        <p class="p-2 mb-2 bg-purple text-white">Score is not available.</p>
    </div>
    <button type="button" *ngIf="!homeButton" class="my-2 btn btn-primary" style="width:100%;"
        (click)="close()">Cancel</button>
    <button type="button" *ngIf="homeButton" class="my-2 btn btn-primary" style="width:100%;" (click)="goToHome()">Back
        To Home</button>
</div>