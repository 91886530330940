<div [class]="theme">
  <div class="mat-app-background">
    <app-nav></app-nav>

    <div class="main-wrapper">
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>
</div>