// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
import env from "./.env";

export const environment = {
  production: false,
  baseUrl: 'https://api.zufoloapp.com/api/v1',
  // baseUrl: 'https://zufolo.dev.yqgpros.com/api/v1',
  version: "1.0.0",
  buildMeta : {
    version_int: 100,
    build_int: 100
  },
  API_URL: "assets/api",
  serverUrl: "/api",
  envName: "DEV",
  firebaseConfig : {
    apiKey: "AIzaSyCLeM7Sjjh4CidexFifryBda9eRO6KuKdk",
    authDomain: "zufolo-aa5be.firebaseapp.com",
    databaseURL: "https://zufolo-aa5be.firebaseio.com",
    projectId: "zufolo-aa5be",
    storageBucket: "zufolo-aa5be.appspot.com",
    messagingSenderId: "865261271244",
    appId: "1:865261271244:web:93ed8c0ae2d59d9b736a69",
    measurementId: "G-HLYFBMHJG7"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
