import { Injectable } from "@angular/core";
import { JsonApiService } from "./json-api.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  FooterNoteText: any;
  constructor(private jsonApiService: JsonApiService) { }

}
