import { Component } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { tap, delay, finalize, catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";
import { AuthService } from "@app/service/auth.service";
import { MustMatch } from "../../../../must-match.validator";
import { promises } from "dns";
declare var $: any;
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent {
  error: string;
  isLoading: boolean;
  registerForm: FormGroup;
  response: any = "";
  privacyAccept = false;
  submitted: boolean;
  cc: any = "64";
  countries: any = [];
  isShowPassword: boolean = false;
  isShowConfirmPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService
  ) {
    this.countries = this.authService.countries;
    console.log(this.countries);
    this.buildForm();
  }

  get f() {
    return this.registerForm.controls;
  }

  registration() {
    this.isLoading = true;
    this.submitted = true;
    console.log("validator =>", this.registerForm);
    if (this.registerForm.invalid) {
      if (this.registerForm.value.email == "") {
        this.toastr.errorToastr("Enter Email ID", "Oops!");
      } else if (!this.isvalidEmail(this.registerForm.value.email)) {
        this.toastr.errorToastr("Enter Valid Email ID", "Oops!");
      } else if (this.registerForm.value.password == "") {
        this.toastr.errorToastr("Enter Password", "Oops!");
      } else if (this.registerForm.value.confirmPassword == "") {
        this.toastr.errorToastr("Enter Confirm Password", "Oops!");
      } else if (this.registerForm.value.password !==
        this.registerForm.value.confirmPassword) {
        this.toastr.errorToastr("Password and Confirm Password does not match.", "Oops!");
      } else if (this.registerForm.value.cc == "") {
        this.toastr.errorToastr("Please Select Country", "Oops!");
      } else if (this.registerForm.value.PhoneNumber == "" && this.registerForm.controls.PhoneNumber.status === "INVALID") {
        this.toastr.errorToastr("Enter Phone Number/Phone Number is wrong", "Oops!");
      } else if (!this.privacyAccept) {
        this.toastr.errorToastr("Please Accept Terms and Conditions.", "Oops!");
        this.isLoading = false;
        this.submitted = false;
        return;
      }
      console.log('____2', this.privacyAccept);
      
      console.log("Valid =>", this.registerForm.controls.PhoneNumber);

      this.isLoading = false;
      this.submitted = false;
      return;
    }
    this.registerLogForPrivacy();
    console.log("validator =>", this.registerForm.controls.PhoneNumber);
    if (!this.isvalidEmail(this.registerForm.value.email)) {
      this.toastr.errorToastr("Enter Valid Email ID", "Oops!");
      this.isLoading = false;
      this.submitted = false;
      return;
    }

    let param: NavigationExtras = {
      queryParams:{
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        confirmPassword: this.registerForm.value.confirmPassword,
        cc: this.registerForm.value.cc,
        PhoneNumber: this.registerForm.value.PhoneNumber
      }
    };
    console.log("queryParams =>", param);

   // this.router.navigate(["/auth/register-otp"], param);

    ///REGISTRATION DONE AFTER VERIFYING EMAIL
    let prms = {
      email: this.registerForm.value.email
    }
     this.response = this.authService.post('user/checkusrexists', prms)
      .pipe(
        catchError(err => {
          console.log("Handling error locally and rethrowing it...", err);
          this.isLoading = false;
          this.submitted = false;
          if (err && err.error && err.error.message) {
                 this.toastr.errorToastr(err.error.message);
          }
         return throwError(err);

        })
      )
    this.response.subscribe(data => {
      this.isLoading = false;
      this.submitted = false;
      console.log(data);

      if (data["status"] == "success") {
        if (data.data ==0)
          {
            this.router.navigate(["/auth/register-otp"], param);
          }else
          {
            this.toastr.errorToastr("This email already has an account please log in ");
          }
      } else {
        console.log("data error message email ");
        this.toastr.errorToastr(data["data"]["message"], "Oops!");
      }
    }),
      error => {
        console.log("data error message email ", error);
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("HttpErrorResponse", "Oops!");
      }
    this.response.catch((error) => {
      console.error('error__', error);
   });

  //   this.response = this.authService.post("user/register", param)
  //     .pipe(
  //       catchError(err => {
  //         console.log("Handling error locally and rethrowing it...", err);
  //         this.isLoading = false;
  //         this.submitted = false;
  //         if (err && err.error && err.error.message) {
  //                this.toastr.errorToastr(err.error.message);
  //         }
  //        return throwError(err);

  //       })
  //     )
  //   this.response.subscribe(data => {
  //     this.isLoading = false;
  //     this.submitted = false;
  //     console.log(data);

  //     if (data["status"] == "success") {
  //       this.router.navigate(["/auth/register-otp"]);
  //       // sessionStorage.setItem('UserId', this.registerForm.value.email);
  //       // sessionStorage.setItem('AccessToken', data.data.AccessToken);

  //       sessionStorage.setItem("UserId", data["data"]["UserId"]);
  //       sessionStorage.setItem("AccessToken", data.data.AccessToken);
  //       sessionStorage.setItem("emailId", this.registerForm.value.email);
  //         //this.router.navigate(["/dashboard/home"]);
  //       // this.router.navigate(["/search"]);
        

  //       this.toastr.successToastr(data["message"], "Success!");
  //     } else {
  //       console.log("data error message email ");
  //       this.toastr.errorToastr(data["data"]["message"], "Oops!");
  //     }
  //   }),
  //     error => {
  //       console.log("data error message email ", error);
  //       this.isLoading = false;
  //       this.submitted = false;
  //       this.toastr.errorToastr("HttpErrorResponse", "Oops!");
  //     }
  //   this.response.catch((error) => {
  //     console.error('error__', error);
  //  })
  }

  private buildForm(): void {
    this.registerForm = this.formBuilder.group(
      {
        email: ["", Validators.required],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        cc: ["", Validators.required],
        privacyAccept: ["", Validators.required],
        PhoneNumber: ["", Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])]
      },
      {
        validator: MustMatch("password", "confirmPassword")
      }
    );
  }

  isvalidEmail(email) {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }
  showConfirmPassword() {
    this.isShowConfirmPassword = !this.isShowConfirmPassword;
  }

  async registerLogForPrivacy() {
    const body = {
      email : this.registerForm.value.email,
      description : 'User has accepted Zufolo terms & conditions.',
      termsId: 1
    };
    this.authService.post('user/termsAcceptance', body).subscribe((res: any) => {
      // this.util.hide();
      console.log('user/termsAcceptance', res);
    }, error => {
      // this.util.hide();
      console.log('eror', error);
    });
  }
}
