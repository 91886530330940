import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./page/login/login.component";
import { RegisterComponent } from "./page/register/register.component";
import { SharedModule } from "@shared/shared.module";

import { AuthRoutingModule } from "./auth.routing";
import { ForgotPasswordComponent } from './page/forgot-password/forgot-password.component';
import { ForgotPasswordOtpComponent } from './page/forgot-password-otp/forgot-password-otp.component';
import { ForgotPasswordResetComponent } from './page/forgot-password-reset/forgot-password-reset.component';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { ZufoloComponent } from './page/zufolo/zufolo.component';
import { UserProfileComponent } from './page/user-profile/user-profile.component';
import { FormsModule } from "@angular/forms";
import { RegisterOtpComponent } from "./page/register-otp/register-otp.component";

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotPasswordComponent, ForgotPasswordOtpComponent, ForgotPasswordResetComponent, ZufoloComponent, UserProfileComponent,RegisterOtpComponent],
  imports: [CommonModule, AuthRoutingModule, SharedModule,NgxSelectModule, FormsModule]
})
export class AuthModule {}
