import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { tap, delay, finalize, catchError } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";
declare var $: any;
import { AuthService } from "@app/service/auth.service";
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AnalyticsService } from "@app/service/analytics.service";

let loginemail;
let password;
let lsRememberMe;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  error: string;
  isLoading: boolean;
  loginForm: FormGroup;
  submitted: boolean;
  response: any = "";
  RememberMe: any;
  isShowPassword: boolean = false;
  guestLogin = {
    email: '',
    password: ''
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private analyticsService: AnalyticsService
  ) {
    this.buildForm();
  }

  ngOnInit() {

    let email = sessionStorage.getItem("emailId");
   
    const isRemeber = localStorage.getItem('lsRememberMe') && localStorage.getItem('lsRememberMe') === 'true'? true:false
    this.loginForm.controls.RememberMe.setValue(isRemeber);
    // this.RememberMe = isRemeber;
    // password = localStorage.getItem('password');

    if (isRemeber && isRemeber != null) {
      this.loginForm.controls.email.setValue(localStorage.getItem('email'));
      this.loginForm.controls.password.setValue(localStorage.getItem('password'));
      // this.login();
    } else {
      this.router.navigate(["/search"]);
    }

    if (email && email != null) {
      this.router.navigate(["/search"]);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  
  
  lsRememberMe() {
    console.log("______dfffffffff", this.loginForm.controls.RememberMe.value)
    if (this.loginForm.controls.RememberMe.value) {
      localStorage.setItem('lsRememberMe', 'true');
      localStorage.setItem('email', this.loginForm.controls.email.value);
    } else {
      // this.login()  
      localStorage.removeItem('lsRememberMe');
      localStorage.removeItem('email');
      // localStorage.clear()
      // localStorage.removeItem
    }
  }
  
  

  login() {
    this.isLoading = true;
    this.submitted = true;
    let shouldByPass = false;
    // sessionStorage.clear();
    if (this.loginForm.invalid) {
      if (this.loginForm.value.email === "") {
        this.toastr.errorToastr("Enter Email ID", "Oops!");
      } else if (this.loginForm.controls.email.status === "INVALID") {
        shouldByPass = true;
      } else if (!this.isvalidEmail(this.loginForm.value.email)) {
        this.toastr.errorToastr("Enter Valid Email ID", "Oops!");
      } else if (this.loginForm.value.password === "") {
        this.toastr.errorToastr("Enter Password", "Oops!");
      }

      if (!shouldByPass) {
        this.isLoading = false;
        this.submitted = false;
        return;
      }
    }

    const param = {
      email: String(this.loginForm.value.email).toLowerCase(),
      password: this.loginForm.value.password
    };

    console.log(this.response);
    // try{
    this.response = this.authService.post("user/login", param);
    // }catch(error) {
    //   console.log(error)
    // }
    this.response
      .pipe(
        catchError(err => {
          console.log("Handling error locally and rethrowing it...", err);
          this.isLoading = false;
          this.submitted = false;
          this.toastr.errorToastr("Invalid email and password!", "Oops!");
          return throwError(err);
        })
      )
      .subscribe(data => {
        this.isLoading = false;
        this.submitted = false;
        console.log(data);
        this.lsRememberMe()
        if (data["status"] == "success") {
          const displayName = data["data"]["DisplayName"]
          sessionStorage.setItem("DisplayName", data["data"]["DisplayName"]);
          sessionStorage.setItem("FirstName", data["data"]["FirstName"]);
          sessionStorage.setItem("LastName", data["data"]["LastName"]);
          sessionStorage.setItem("PhotoPath", data["data"]["PhotoPath"]);
          sessionStorage.setItem("UserId", data["data"]["UserId"]);
          sessionStorage.setItem("AccessToken", data["data"]["AccessToken"]);

          sessionStorage.setItem("emailId", encodeURIComponent(this.loginForm.value.email));
          //this.router.navigate(["/dashboard/home"]);
          this.router.navigate(["/search"]);
          this.toastr.successToastr(data["message"], "Success!");
          this.analyticsService.setUserId(this.loginForm.value.email);
          this.analyticsService.setUserProperty("name", displayName);
        } else if (data["status"] == "error") {
          this.isLoading = false;
          this.submitted = false;
          this.toastr.errorToastr("Invalid email and password!", "Oops!");
        }
      }),
      (err, error) => {
        console.log(err);
        console.log(error);
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("Invalid email and password!", "Oops!");
      };
  }

  private buildForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      password: ["", Validators.required],
      RememberMe: [""]
    });
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
  }
  isvalidEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  loginWithFacebook(){
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((res: any)=>{
      console.log("login fb success=>", res);
      if (res && res.email) {
        const parma = {
          email: res.email
        };
        let displayName = '';
        let firstName = '';
        let emailId = '';
        let userPic = '';
        let lastName = '';
        if (res && res.name) {
          displayName = res.name;
          sessionStorage.setItem('displayName', displayName);
        }
        if (res && res.email) {
          emailId = res.email;
          this.analyticsService.setUserId(emailId)
          sessionStorage.setItem('email', emailId);
          sessionStorage.setItem('uid', emailId);
        }
        if (res && res.picture && res.picture.data && res.picture.data.url) {
          userPic = res.picture.data.url;
          sessionStorage.setItem('userPic', userPic);
        }
        this.authService.post('user/socialLogin', parma).subscribe((data: any) => {
          // this.util.hide();
          // const data = JSON.parse(datas.data);
          console.log('socialLogin____', data);
          if (data && data.Data && data.status === 'success') {
            if (res.email && res.email !== 'NA') {
              sessionStorage.setItem('emailId', res.email);
            }

            if(data.Data.data && data.Data.data.UserId) {
              sessionStorage.setItem('UserId',String(data.Data.data.UserId));
            }

            if(data.Data.data &&  data.Data.data.AccessToken) {
              sessionStorage.setItem('AccessToken',String(data.Data.data.AccessToken));
            }
            if (data.Data.data && data.Data.data.DisplayName) {
              displayName = data.Data.data.DisplayName;
              sessionStorage.setItem('DisplayName', displayName);
            }
            if (data.Data.data && data.Data.data.FirstName) {
              firstName = data.Data.data.FirstName;
              sessionStorage.setItem('FirstName', firstName);
            }
            if (data.Data.data && data.Data.data.LastName) {
              lastName = data.Data.data.LastName;
              sessionStorage.setItem('LastName', lastName);
            }
            if (data.Data.data && data.Data.data.PhotoPath) {
              userPic = data.Data.data.PhotoPath;
              localStorage.setItem('PhotoPath', userPic);
            }
            this.analyticsService.logEvent('login', { method: 'fb'});
            this.analyticsService.setUserProperty("name", displayName);
            this.router.navigate(['/search']);
            // this.navCtrl.navigateRoot(['']);
          }
        }, error => {
          console.log(error);
          console.log("error google login api=>",error);
        this.toastr.errorToastr('Authencation failed with the facebook', 'Social login error !')
        })
      }
    }).catch(err=>{
      console.log("err login fb =>", err);
      this.toastr.errorToastr('Authencation failed with the facebook', 'Social login error !')
    })
  }

  loginWithGoogle(){
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res: any)=>{
      console.log("login google success=>", res)
      const parma = {
        email: res.email
      };
      let displayName = '';
      let firstName = '';
      let emailId = '';
      let userPic = '';
      let lastName= '';
      if (res && res.displayName) {
        displayName = res.displayName;
        sessionStorage.setItem('displayName', res.displayName);
      }
      if (res && res.email) {
        emailId = res.email;
        this.analyticsService.setUserId(emailId)
        sessionStorage.setItem('email', emailId);
        sessionStorage.setItem('uid', emailId);
      }
      if (res && res.imageUrl) {
        userPic = res.imageUrl;
        sessionStorage.setItem('userPic', res.imageUrl);
      }
      // this.util.show();
      this.authService.post('user/socialLogin', parma).subscribe((data: any) => {
        // this.util.hide();
        // const data = JSON.parse(datas.data);
        console.log('socialLogin____', data);
        if (data && data.Data && data.status === 'success') {
          if (res.email && res.email !== 'NA') {
            sessionStorage.setItem('emailId', res.email);
          }

          if(data.Data.data && data.Data.data.UserId) {
            sessionStorage.setItem('UserId',String(data.Data.data.UserId));
          }

          if(data.Data.data &&  data.Data.data.AccessToken) {
            sessionStorage.setItem('AccessToken',String(data.Data.data.AccessToken));
          }
          
          if (data.Data.data && data.Data.data.DisplayName) {
            displayName = data.Data.data.DisplayName;
            sessionStorage.setItem('DisplayName', displayName);
          }
          if (data.Data.data && data.Data.data.FirstName) {
            firstName = data.Data.data.FirstName;
            sessionStorage.setItem('FirstName', firstName);
          }
          if (data.Data.data && data.Data.data.LastName) {
            lastName = data.Data.data.LastName;
            sessionStorage.setItem('LastName', lastName);
          }
          if (data.Data.data && data.Data.data.PhotoPath) {
            userPic = data.Data.data.PhotoPath;
            sessionStorage.setItem('PhotoPath', userPic);
          }

       this.analyticsService.logEvent('login', { method: 'google'});
       this.analyticsService.setUserProperty("name", displayName);
       this.router.navigate(["/search"]);
          // this.navCtrl.navigateRoot(['']);
        }
      }, error => {
        console.log("error google login api=>",error);
        this.toastr.errorToastr('Authencation failed with the google', 'Social login error !')
        // this.util.hide();
        // this.util.showToast('Sorry, the user name and password\n' + ' you entered do not match. Please try again', 'danger', 'bottom');
      });
    }).catch(err=>{
      this.toastr.errorToastr('Authencation failed with the google', 'Social login error !')
      console.log("err login google =>", err);
    })
  }

}
