import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env";
import { Observable } from "rxjs";
import { ThemeService } from "app/core/service/theme.service";
import { AuthService } from "@app/service/auth.service";
import { NavigationEnd } from "@angular/router";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"]
})
export class NavComponent implements OnInit {
  public version = environment.version;
  public repoUrl = "https://github.com/mathisGarberg/angular-folder-structure";
  public isDarkTheme$: Observable<boolean>;
  searchtext: any;
  profileObj: any;

  navItems = [
    { link: "/dashboard/home", title: "Home" },
    { link: "/about", title: "About" },
    { link: "/contact", title: "Contact" }
  ];

  constructor(
    // public router: Router,
    private themeService: ThemeService,
    public as: AuthService,
    public router: Router
  ) { 
    this.profileObj = {
      displayName: sessionStorage.getItem("DisplayName") ? sessionStorage.getItem('DisplayName') : sessionStorage.getItem('emailId'),
      photoPath: sessionStorage.getItem("PhotoPath"),
      emailid: sessionStorage.getItem("emailid"),
      accessToken: sessionStorage.getItem("AccessToken")
    };
  }

  ngOnInit() {
    this.profileObj = {
      displayName: sessionStorage.getItem("DisplayName") ? sessionStorage.getItem('DisplayName') : sessionStorage.getItem('emailId'),
      photoPath: sessionStorage.getItem("PhotoPath"),
      emailid: sessionStorage.getItem("emailid"),
      accessToken: sessionStorage.getItem("AccessToken")
    };
    console.log("profile object=>", this.profileObj);
    this.isDarkTheme$ = this.themeService.getDarkTheme();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  toggleTheme(checked: boolean) {
    this.themeService.setDarkTheme(checked);
  }
  navigateToAnalyze() {
    this.router.navigate(["/restaurant/venue-detail"]);
  }
  navigateToSearch() {
    this.router.navigate(["/search"]);
  }
  navigateToVoucher() {
    this.router.navigate(["/vouchers"]);
  }
  navigateToReview() {
    this.router.navigate(["/review/review"]);
  }
  navigateTohelp() {
    this.router.navigate(["/help"]);
  }
  privacyLink() {
    window.open("https://zufoloapp.s3.amazonaws.com/Terms/ZufoloTerms.html", "_blank");
  }

  logout() {
    sessionStorage.clear()
    // localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }

  goToProfile() {
    this.router.navigate(['/search/user-profile']);
  }
}
