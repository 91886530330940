import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { tap, delay, finalize, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";
declare var $: any;
import { AuthService } from "@app/service/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  error: string;
  isLoading: boolean;
  submitted: boolean;
  fpasswordForm: FormGroup;
  response: any = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrManager,
    private authService: AuthService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {}
  get f() {
    return this.fpasswordForm.controls;
  }

  forgotpassword() {
    this.isLoading = true;
    this.submitted = true;

    if (this.fpasswordForm.invalid) {
      if (this.fpasswordForm.value.email == "") {
        this.toastr.errorToastr("Enter Email ID", "Oops!");
      } else if (!this.isvalidEmail(this.fpasswordForm.value.email)) {
        this.toastr.errorToastr("Enter Valid Email ID", "Oops!");
      } else if (this.fpasswordForm.value.method == "") {
        this.toastr.errorToastr("Select Method", "Oops!");
      }

      this.isLoading = false;
      this.submitted = false;
      return;
    }

    let param = {
      email: this.fpasswordForm.value.email,
      method: this.fpasswordForm.value.method
    };

    this.response = this.authService.post("user/forgotPassword", param);
    this.response.subscribe(data => {
      this.isLoading = false;
      this.submitted = false;
      console.log(data);

      if (data["status"] == "success") {
        sessionStorage.setItem("email", this.fpasswordForm.value.email);
        this.router.navigate(["/auth/forgot-password-otp"]);
        this.toastr.successToastr(data["message"], "Success!");
      } else {
        this.toastr.errorToastr(data["data"]["message"], "Oops!");
      }
    }),
      err => {
        this.isLoading = false;
        this.submitted = false;
        this.toastr.errorToastr("HttpErrorResponse", "Oops!");
      };
  }

  private buildForm(): void {
    this.fpasswordForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]
      ],
      method: ["", Validators.required]
    });
  }
  isvalidEmail(email) {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }
}
