import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(public router:Router){

  }
  canActivate(): boolean {
    let userExist = sessionStorage.getItem('emailId')
    if(userExist && userExist != null){
      return true;
    }else{
      this.router.navigate(['/user/login'])
      return false;
    }
  }
}
