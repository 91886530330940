<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
  <div class="container">
    <a class="navbar-brand text-white text-uppercase font-weight-normal" (click)="navigateToSearch()">
      <img src="../../../assets/images/logo.png" width="50px" height="50px" /> Zufolo
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="profileObj.displayName && profileObj.displayName != null" class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav ml-auto align-items-baseline">
        <!-- <li class="nav-item active">
          <a class="nav-link" href="#">Home
            <span class="sr-only">(current)</span>
          </a>
        </li> -->
        <li class="nav-item ">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="navigateToSearch()">Search</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="navigateToReview()">Review</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="navigateToAnalyze()">Owner Portal</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="navigateToVoucher()">My vouchers</a>
        </li>


        <!-- <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
            aria-expanded="false" aria-label="Toggle navigation" (click)="navigateToVoucher()">Calendar</a>
        </li> -->


        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="navigateTohelp()">Help</a>
        </li>
        <li class="nav-item" *ngIf="profileObj.displayName === null || profileObj.displayName === ''">
          <a class="nav-link" data-toggle="collapse" data-target="#navbarResponsive"
      aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" [routerLink]="['/auth/login']">Login</a>
        </li>
        <li class="nav-item" *ngIf="profileObj.displayName === null || profileObj.displayName === ''">
          <a class="nav-link" [routerLink]="['/auth/register']">Signup</a>
        </li>
        <li class="nav-item" *ngIf="profileObj.displayName != null || profileObj.displayName != ''">
          <div class="dropdown">
            <a href="#" class="dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" class="nav-link profile">{{profileObj.displayName}} <span
                class="pull-right"><img [src]="profileObj.photoPath" alt="Profile" onerror='this.src="../../../../../assets/images/profile/profile.jpg";'/></span></a>
            <div class="dropdown-menu my-dropdown-menu" aria-labelledby="dropdownMenu2">
              <button class="dropdown-item" type="button" (click)="goToProfile()"><img alt=""
                  src="/assets/images/sidemenu/user.png" />Profile</button>
              <!-- <button class="dropdown-item" type="button"><img alt=""
                  src="/assets/images/sidemenu/settings.png" />Settings</button> -->
              <button class="dropdown-item" type="button" (click)="privacyLink()"><img alt=""
                  src="/assets/images/sidemenu/lock.png" />Privacy</button>
              <!-- <button class="dropdown-item" type="button"><img alt=""
                  src="/assets/images/sidemenu/language.png" />Language</button> -->
              <button class="dropdown-item" type="button" (click)="logout()"><img alt=""
                  src="/assets/images/sidemenu/switch.png" />Logout</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- <div class="bg-muted">
  <div class="container quick-link-header">
    <ul class="m-0 p-2 d-flex justify-content-end">
      <li><a href="#">Review</a></li>
      <li><a href="#">Analyze</a></li>
      <li><a href="#">My vouchers</a></li>
      <li><a href="#">Help</a></li>
    </ul>
  </div>
</div> -->
<!--<div class="container mt-4">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <form>
        <div class="search-box">
          <div class="form-group has-search w-100">
            <span class="pi pi-search form-control-feedback"></span>
            <input type="text" name="searchtext" class="search-input form-control border-right-0" placeholder="Search for a cafe or restaurant by cuisine, event & special offers" [(ngModel)]="searchtext" />
          </div>
          <div class="city-dropdown dropdown">
            <button type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" class="btn btn-light dropdown-toggle border-left-0">
              <i class="text-danger pi pi-map-marker i-1 mr-1"></i>Bhavnagar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Rajkot</a>
              <a class="dropdown-item" href="#">gandhinagar</a>
              <a class="dropdown-item" href="#">Ahmedabad</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>-->
<!-- <div class="wrapper">
  <div class="toolbar">
    <mat-toolbar color="primary">
      <div class="container">
        <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none">
          {{ 'Angular Folder Structure' }}
        </span>
        <span routerLink="" class="branding spacer d-none d-md-inline">
          {{ 'Angular Folder Structure' }}
        </span>
        <span class="d-none d-md-inline">
          <button mat-button class="nav-button" *ngFor="let item of navItems" [routerLink]="[item.link]"
            routerLinkActive="active">
            {{ item.title }}
          </button>

          <mat-slide-toggle [checked]="isDarkTheme$ | async" (change)="toggleTheme($event.checked)">Toggle Theme</mat-slide-toggle>
        </span>
        <div style="float: right;">
          <a [href]="repoUrl" target="_blank">Fork on GitHub
            <fa-icon [icon]="['fab','github']"></fa-icon>
          </a>
        </div>
      </div>
    </mat-toolbar>
  </div>
</div> -->