import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./page/login/login.component";
import { RegisterComponent } from "./page/register/register.component";
import { ForgotPasswordComponent } from './page/forgot-password/forgot-password.component';
import { ForgotPasswordOtpComponent } from './page/forgot-password-otp/forgot-password-otp.component';
import { ForgotPasswordResetComponent } from './page/forgot-password-reset/forgot-password-reset.component';
import { ZufoloComponent } from './page/zufolo/zufolo.component';
import { UserProfileComponent } from "./page/user-profile/user-profile.component";
import { RegisterOtpComponent } from "./page/register-otp/register-otp.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full"
  },
  {
    path: "",
    children: [
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "register",
        component: RegisterComponent
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent
      },
      {
        path: "forgot-password-otp",
        component: ForgotPasswordOtpComponent
      },
            {
        path: "register-otp",
        component: RegisterOtpComponent
      },
      {
        path: "forgot-password-reset",
        component: ForgotPasswordResetComponent
      },
      {
        path: "zufolo",
        component: ZufoloComponent
      },
      {
        path: "user-profile",
        component: UserProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
